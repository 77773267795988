import { UploadFile } from 'antd';
import DragAndDropFileInput from 'components/DragAndDropFileInput';
import {
  ShowedMessage,
  StatefulUploadFile
} from 'components/DragAndDropFileInput/interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFileUploadState,
  setCurrentlyUploadingFile,
  setFilesToUpload
} from 'store/slices/fileUploadSlice';
import { RootState } from 'store/store';
import { UploadDocumentProps } from './interface';

const UploadDocument = ({
  maxCount = 10,
  multiple = true,
  accept = '.pdf,.jpeg,.jpg',
  description = '(PDF, JPG, PNG unterstützt - Maximal 10 MB pro Datei)',
  uploadedFilesLength,
  required,
  componentRef,
  setUploadedDocumentIds,
  onUpload
}: UploadDocumentProps) => {
  const dispatch = useDispatch();
  const fileUploadData = useSelector((state: RootState) => state.fileUpload);

  const [uploadFiles, setUploadFiles] = useState<StatefulUploadFile[]>([]);
  const [numToBeUploaded, setNumToBeUploaded] = useState<number>(0);
  const [uploadedStatuses, setUploadedStatuses] = useState<string[]>([]);

  const [showedMessage, setShowedMessage] = useState<ShowedMessage | null>(
    null
  );

  const errorMessage = useSelector(
    (state: RootState) => state.errors.errorMessage
  );

  const handlePrepareForUpload = (numToBeUploaded: number) => {
    setUploadedStatuses([]);
    setNumToBeUploaded(numToBeUploaded);
  };

  const handleFileUpload = (file: UploadFile) => {
    dispatch(setCurrentlyUploadingFile(file.uid));
    onUpload(file);
  };

  useEffect(() => {
    dispatch(resetFileUploadState());
  }, []);

  useEffect(() => {
    dispatch(setFilesToUpload(uploadFiles));

    const uploadedFiles = uploadFiles.filter(
      (file) => file.state === 'succeeded' && file.generatedId
    );

    if (uploadedFiles.length > 0) {
      const uploadedFileIds = uploadedFiles
        .map((file) => file.generatedId)
        .filter((id): id is string => id !== null);
      setUploadedDocumentIds(uploadedFileIds);

      if (uploadFiles.length === uploadedFileIds.length) {
        setShowedMessage(null);
      }
    }

    if (uploadFiles.length === 0) {
      dispatch(resetFileUploadState());
    }
  }, [uploadFiles]);

  useEffect(() => {
    const { status } = fileUploadData;
    if (status === 'succeeded') {
      setUploadedStatuses((prev: string[]) => [...prev, 'succeeded']);
    } else if (status === 'failed') {
      setUploadedStatuses((prev: string[]) => [...prev, 'failed']);
    }

    if (['failed', 'succeeded', 'loading'].includes(status)) {
      const { currentlyUploadingFileId, data, status } = fileUploadData;
      const currentlyUploadingFile = uploadFiles.find(
        (file) => file.uid === currentlyUploadingFileId
      );
      if (currentlyUploadingFile) {
        currentlyUploadingFile.state = status;
        if (status === 'succeeded') {
          const { fileId } = data;
          currentlyUploadingFile.generatedId = fileId;
        }
        const newFileList = uploadFiles.map((file) => {
          if (file.uid === currentlyUploadingFileId)
            return currentlyUploadingFile;
          return file;
        });

        setUploadFiles([...newFileList]);
      }
    }
  }, [fileUploadData.status]);

  useEffect(() => {
    if (uploadedStatuses.length > 0) {
      if (numToBeUploaded === uploadedStatuses.length) {
        if (uploadedStatuses.every((status) => status === 'succeeded')) {
          setShowedMessage({
            message: 'Datei erfolgreich hochgeladen.',
            status: 'success'
          });
        } else {
          const numOfErrors = uploadFiles.filter(
            (file) => file.state === 'failed'
          ).length;

          if (fileUploadData.error?.includes('415') && numOfErrors === 1) {
            setShowedMessage({
              message: 'Dieser Dateityp ist unzulässig!',
              status: 'error'
            });
          } else if (
            fileUploadData.error?.includes('400') &&
            numOfErrors === 1
          ) {
            setShowedMessage({
              message: 'Dies ist eine potentiell bösartige Datei!',
              status: 'error'
            });
          } else {
            const errorMessageWithFailNum =
              numOfErrors > 1
                ? `${numOfErrors} Dateien konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.`
                : fileUploadData.error || '';

            setShowedMessage({
              message: errorMessageWithFailNum
                ? errorMessageWithFailNum
                : 'Dieser Dateityp ist unzulässig!',
              status: 'error'
            });
          }
        }
      }
    }
  }, [uploadedStatuses, numToBeUploaded]);

  useEffect(() => {
    if (errorMessage) {
      setShowedMessage({
        message: `Bitte bestätigen Sie das Hochladen der Datei(en) um fortzufahren!`,
        status: 'error'
      });
    } else {
      setShowedMessage(null);
    }
  }, [errorMessage, uploadedFilesLength]);

  useEffect(() => {
    if (uploadedFilesLength) {
      setShowedMessage({
        message: `Sie haben bereits ${uploadedFilesLength} Dateien hochgeladen. Wenn Sie eine weitere Datei hochladen, wird der Prozess neu gestartet. Die bisherigen Dateien werden damit gelöscht und alle gewünschten Dateien müssen erneut hochgeladen werden.`,
        status: 'warning'
      });
    }

    if (required && componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [componentRef.current, required]);

  return (
    <DragAndDropFileInput
      files={uploadFiles}
      maxCount={maxCount}
      multiple={multiple}
      name={'upload_file'}
      accept={accept}
      setFiles={setUploadFiles}
      description={description}
      handleUpload={handleFileUpload}
      showedMessage={showedMessage}
      setShowedMessage={setShowedMessage}
      prepareForUpload={handlePrepareForUpload}
    />
  );
};

export default UploadDocument;
