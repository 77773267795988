import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store/store';

import {
  CurrentConnectionOwner,
  ExistingConnectionData,
  ExistingPhoneNumber,
  Packages,
  PreviousProvider
} from 'types/bundle';

import {
  selectedExistingConnection,
  setExistingConnection,
  setExistingConnectionOwnerOptions,
  setExistingConnectionPhoneNumbers
} from 'store/slices/selectedDataSlice';

import { getProvidersList } from 'store/slices/previousProvidersSlice';

import {
  Checkbox,
  CheckboxProps,
  Col,
  Radio,
  RadioChangeEvent,
  Row,
  Tooltip
} from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';

import InfoIcon from 'assets/images/svg/info_icon.svg';
import { ReactComponent as InfoIconComponent } from 'assets/images/svg/info_icon.svg';
import { ReactComponent as WarningIcon } from 'assets/images/svg/error-icon.svg';

import style from './style.module.css';
import { existingConnectionsErrors } from 'store/slices/validationSlice';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import ErrorMessage from 'components/ErrorMessage';
import {
  ADDITIONAL_PHONE_NUMBER_PRODUCT_ID,
  ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE,
  ENTER_PERSONAL_INFO_MESSAGE,
  PORT_PHONE_NUMBERS_PRODUCT_ID
} from 'constants/Constants';
import { ServiceType } from 'store/slices/selectedServices';
import { getTitleOptions } from 'utils/Utils';
import Message from 'components/Message';
import { MessageType } from 'components/Message/interface';

const GERMANY_COUNTRY_CODE = '+49';
const EXISTING_PHONE_NUMBER = {
  countryCode: GERMANY_COUNTRY_CODE,
  areaCode: '',
  phoneNumber: ''
};

const EXISTING_OWNER_OPTIONS: CurrentConnectionOwner = {
  equalToCustomer: true,
  title: '',
  name: '',
  lastName: ''
};

const ExistingConnection = () => {
  const dispatch = useDispatch<AppDispatch>();

  const selectedProducts: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const existingConnectionData: ExistingConnectionData = useSelector(
    selectedExistingConnection
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const errors = useSelector(existingConnectionsErrors);

  const [cancelMyCurrentPlan, setCancelMyCurrentPlan] = useState<boolean>(
    existingConnectionData?.cancelMyCurrentPlan
  );

  const [portMyPhoneNumber, setPortMyPhoneNumber] = useState<boolean>(
    existingConnectionData.portMyCurrentPhoneNumber
  );

  const [previousProvider, setPreviousProvider] = useState<string>(
    existingConnectionData.previousProvider
  );

  const [currentConnectionOwner, setCurrentConnectionOwner] =
    useState<CurrentConnectionOwner>(
      existingConnectionData.currentConnectionOwner
    );

  const [currentConnectionOwnerOption, setCurrentConnectionOwnerOption] =
    useState<string | boolean>(
      existingConnectionData.currentConnectionOwner.equalToCustomer
    );

  const [existingPhoneNumberFields, setExistingPhoneNumberFields] = useState<
    ExistingPhoneNumber[]
  >(existingConnectionData.existingPhoneNumber);

  const [providersList, setProvidersList] = useState<any>([]);

  const phoneNumberProduct = selectedProducts?.find(
    (prod) => prod?.service?.productID === ADDITIONAL_PHONE_NUMBER_PRODUCT_ID
  );

  const INITIAL_EXISTING_PHONE_NUMBERS = Array.from(
    { length: phoneNumberProduct?.amount || 0 },
    () => EXISTING_PHONE_NUMBER
  );

  useEffect(() => {
    const currentPhoneNumbers = existingPhoneNumberFields || [];
    const newAmount = phoneNumberProduct?.amount || 0;
    let existingPhoneNumber = EXISTING_PHONE_NUMBER;

    if (currentPhoneNumbers?.length > 0) {
      existingPhoneNumber = {
        ...existingPhoneNumber,
        areaCode: currentPhoneNumbers[0].areaCode
      };
    }

    // Adjust the array size while preserving existing values
    const updatedPhoneNumbers = Array.from(
      { length: newAmount },
      (_, index) => {
        return currentPhoneNumbers[index] || existingPhoneNumber;
      }
    );

    setExistingPhoneNumberFields(updatedPhoneNumbers as ExistingPhoneNumber[]);

    dispatch(setExistingConnectionPhoneNumbers(updatedPhoneNumbers));
  }, [phoneNumberProduct]);

  const resetExistingPhoneNumbers = (): void => {
    if (!cancelMyCurrentPlan && !portMyPhoneNumber) {
      setExistingPhoneNumberFields(
        INITIAL_EXISTING_PHONE_NUMBERS as ExistingPhoneNumber[]
      );

      dispatch(
        setExistingConnectionPhoneNumbers(INITIAL_EXISTING_PHONE_NUMBERS)
      );
    }
  };

  const resetCurrentConnectionOwnerFields = () => {
    setCurrentConnectionOwnerOption(EXISTING_OWNER_OPTIONS.equalToCustomer);
    setCurrentConnectionOwner({
      ...EXISTING_OWNER_OPTIONS
    });

    dispatch(setExistingConnectionOwnerOptions(EXISTING_OWNER_OPTIONS));
  };

  const handleCancelCurrentPlan: CheckboxProps['onChange'] = ({
    target: { checked }
  }) => {
    const shouldReset = shouldResetProvider(checked, portMyPhoneNumber);
    const updatedProvider = shouldReset ? '' : previousProvider;

    setCancelMyCurrentPlan(checked);
    if (shouldReset) setPreviousProvider(updatedProvider);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        cancelMyCurrentPlan: checked,
        previousProvider: updatedProvider
      })
    );
  };

  const handlePortMyPhoneNumber: CheckboxProps['onChange'] = ({
    target: { checked }
  }) => {
    const shouldReset = shouldResetProvider(cancelMyCurrentPlan, checked);
    const updatedProvider = shouldReset ? '' : previousProvider;

    setPortMyPhoneNumber(checked);
    if (shouldReset) setPreviousProvider(updatedProvider);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        portMyCurrentPhoneNumber: checked,
        previousProvider: updatedProvider
      })
    );
  };

  const shouldResetProvider = (cancel: boolean, port: boolean) =>
    !cancel && !port;

  const handlePreviousProvider = (event: string) => {
    setPreviousProvider(event);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        previousProvider: event
      })
    );
  };

  const handleExistingPhoneFieldsChange = (
    index: number,
    key: keyof ExistingPhoneNumber,
    value: string
  ) => {
    const newFormFields: ExistingPhoneNumber[] = [...existingPhoneNumberFields];

    newFormFields[index] = {
      ...newFormFields[index],
      [key]: value.replace(/\D/g, '')
    };

    const sanitizedFormFields = newFormFields.map((field) => ({
      ...field,
      areaCode: field.areaCode.replace(/^0+/, '').replace(/\D/g, ''),
      phoneNumber: field.phoneNumber.replace(/^0+/, '').replace(/\D/g, '')
    }));

    setExistingPhoneNumberFields(newFormFields);

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        existingPhoneNumber: sanitizedFormFields
      })
    );
  };

  const handleAreaCodeChange = (value: string) => {
    const newFormFields: ExistingPhoneNumber[] = [...existingPhoneNumberFields];

    const updatedAreaCodes = newFormFields.map((phone) => ({
      ...phone,
      areaCode: value.replace(/\D/g, '')
    }));

    const sanitizedAreaCodes = newFormFields.map((phone) => ({
      ...phone,
      areaCode: value.replace(/^0+/, '').replace(/\D/g, ''),
      phoneNumber: phone.phoneNumber.replace(/^0+/, '').replace(/\D/g, '')
    }));

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        existingPhoneNumber: sanitizedAreaCodes
      })
    );

    setExistingPhoneNumberFields(updatedAreaCodes);
  };

  const handleOptionChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setCurrentConnectionOwnerOption(value);

    setCurrentConnectionOwner({
      ...currentConnectionOwner,
      equalToCustomer: value
    });

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        currentConnectionOwner: {
          ...currentConnectionOwner,
          equalToCustomer: value
        }
      })
    );

    if (value === true) resetCurrentConnectionOwnerFields();
  };

  const handleUserInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentConnectionOwner({
      ...currentConnectionOwner,
      [event.target.name]: event.target.value
    });

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        currentConnectionOwner: {
          ...currentConnectionOwner,
          [event.target.name]: event.target.value
        }
      })
    );
  };

  const handleTitleChange = (value: string) => {
    setCurrentConnectionOwner({
      ...currentConnectionOwner,
      title: value
    });

    dispatch(
      setExistingConnection({
        ...existingConnectionData,
        currentConnectionOwner: {
          ...currentConnectionOwner,
          title: value
        }
      })
    );
  };

  const isPortingSelected = !!selectedProducts.find(
    (product: ServiceType) =>
      product.service.productID === PORT_PHONE_NUMBERS_PRODUCT_ID
  );

  const hasPortingOption = useSelector(
    (state: RootState) => state.portingOption.hasPortingOption
  );

  useEffect(() => {
    dispatch(getProvidersList()).then((result) => {
      result.payload && setProvidersList(result.payload);
    });
  }, [dispatch]);

  useEffect(() => {
    if (hasPortingOption) {
      setPortMyPhoneNumber(isPortingSelected);
      dispatch(
        setExistingConnection({
          ...existingConnectionData,
          portMyCurrentPhoneNumber: isPortingSelected
        })
      );
    }
  }, [hasPortingOption]);

  useEffect(() => {
    if (!cancelMyCurrentPlan && !portMyPhoneNumber) {
      resetCurrentConnectionOwnerFields();
      resetExistingPhoneNumbers();
    }
  }, [cancelMyCurrentPlan, portMyPhoneNumber]);

  return (
    <div className={style.connection_container}>
      {!phoneNumberProduct && (
        <Message messageType={MessageType.WARNING} icon={<WarningIcon />}>
          Dieses Servicepaket enthält keine Telefonie. Sollten Sie aktuell
          Rufnummern haben, können diese nicht übernommen werden.
        </Message>
      )}
      <Row gutter={[24, 24]} className={style.check_options_wrapper}>
        <Col xs={24} lg={12}>
          <Checkbox
            className="custom_style_checkbox"
            checked={cancelMyCurrentPlan}
            onChange={handleCancelCurrentPlan}
          >
            Ich beauftrage MUENET mit der Kündigung meines bestehenden Vertrags.
          </Checkbox>
        </Col>
        {phoneNumberProduct && (
          <Col xs={24} lg={12}>
            <Tooltip
              title={
                hasPortingOption && !portMyPhoneNumber
                  ? 'Sie müssen bei Produkte & Services unter Telefonie die Rufnummernportierung buchen, damit wir ihre alte Rufnummer übernehmen können.'
                  : ''
              }
            >
              <Checkbox
                className="custom_style_checkbox"
                checked={portMyPhoneNumber}
                onChange={handlePortMyPhoneNumber}
                disabled={hasPortingOption}
              >
                Ich wünsche die Übernahme meiner alten Rufnummer zu MUENET.
              </Checkbox>
            </Tooltip>
          </Col>
        )}
      </Row>

      <Row gutter={24}>
        <Col span={24} sm={12}>
          <FloatingLabelSelect
            label="Bisheriger Anbieter*"
            value={
              cancelMyCurrentPlan || portMyPhoneNumber ? previousProvider : ''
            }
            options={providersList.map((provider: PreviousProvider) => ({
              label: provider.name,
              value: provider.name
            }))}
            onChangeHandler={handlePreviousProvider}
            disabled={!(cancelMyCurrentPlan || portMyPhoneNumber)}
            errorStatus={errors?.previousProvider ? 'error' : ''}
            errorMessage={errors?.previousProvider}
            showSearch
          />
        </Col>
      </Row>

      <div className={style.transfer_warning_wrapper}>
        <img src={InfoIcon} alt="info_icon" />
        <p className={style.transfer_warning_text}>
          Bitte beachten Sie, dass wir nur die Telefonnummern Ihres direkten
          Providers kündigen. Wenn Sie Telekommunikationdienste von Dritten
          beziehen, müssen Sie diese selbst kündigen!
        </p>
      </div>

      {(portMyPhoneNumber || cancelMyCurrentPlan) && (
        <>
          <p className={style.section_sub_title}>Bestehender Vertragspartner</p>

          <Radio.Group
            onChange={handleOptionChange}
            value={currentConnectionOwnerOption}
          >
            <Row
              justify={'start'}
              gutter={[0, 24]}
              className={style.radio_wrapper}
            >
              <Col span={24} xl={6}>
                <Radio value={true}>Auftraggeber</Radio>
              </Col>
              <Col span={24} xl={12}>
                <Radio value={false}>Sonstige (bitte angeben)</Radio>
              </Col>
            </Row>
          </Radio.Group>

          {currentConnectionOwnerOption === false && (
            <>
              {(errors.currentConnectionOwner?.company ||
                (errors.currentConnectionOwner?.title &&
                  currentConnectionOwner.title === '') ||
                (errors.currentConnectionOwner?.name &&
                  currentConnectionOwner.name === '') ||
                (errors.currentConnectionOwner?.lastName &&
                  currentConnectionOwner.lastName === '')) && (
                <div className={style.error_message_wrapper}>
                  <ErrorMessage
                    errorMessage={
                      currentPackage?.customer_group_firm
                        ? ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE
                        : ENTER_PERSONAL_INFO_MESSAGE
                    }
                  />
                </div>
              )}

              {currentPackage?.customer_group_firm && (
                <Row className={style.connection_owner_company_wrapper}>
                  <Col span={24}>
                    <FloatingLabelInput
                      label="Firma*"
                      value={currentConnectionOwner?.company}
                      onChangeHandler={handleUserInfoChange}
                      name="company"
                      errorStatus={
                        errors?.currentConnectionOwner?.company ? 'error' : ''
                      }
                      errorMessage={errors?.currentConnectionOwner?.company}
                    />
                  </Col>
                </Row>
              )}

              <Row
                gutter={12}
                className={
                  currentPackage?.customer_group_firm
                    ? style.connection_owner_info_wrapper_mb
                    : style.connection_owner_info_wrapper
                }
              >
                <Col span={8} md={6}>
                  <FloatingLabelSelect
                    value={currentConnectionOwner?.title}
                    label="Anrede*"
                    options={getTitleOptions(
                      currentPackage?.customer_group_firm
                    )}
                    onChangeHandler={handleTitleChange}
                    errorStatus={
                      errors?.currentConnectionOwner?.title ? 'error' : ''
                    }
                    errorMessage={errors?.currentConnectionOwner?.title}
                  />
                </Col>
                <Col span={16} md={9}>
                  <FloatingLabelInput
                    name="name"
                    label="Vorname*"
                    value={currentConnectionOwner.name}
                    onChangeHandler={handleUserInfoChange}
                    errorStatus={
                      errors?.currentConnectionOwner?.name ? 'error' : ''
                    }
                    errorMessage={errors?.currentConnectionOwner?.name}
                  />
                </Col>
                <Col span={24} md={9}>
                  <FloatingLabelInput
                    name="lastName"
                    label="Nachname*"
                    value={currentConnectionOwner?.lastName}
                    onChangeHandler={handleUserInfoChange}
                    errorStatus={
                      errors?.currentConnectionOwner?.lastName ? 'error' : ''
                    }
                    errorMessage={errors?.currentConnectionOwner?.lastName}
                  />
                </Col>
              </Row>
            </>
          )}

          <div className={style.transfer_warning_wrapper}>
            <img src={InfoIcon} alt="info_icon" />
            <p className={style.transfer_warning_text}>
              Die Kontaktdaten des Auftraggebers werden im nächsten Schritt
              unter &quot;Persönliche Informationen&quot; erfasst.
            </p>
          </div>
        </>
      )}

      <>
        {portMyPhoneNumber && (
          <>
            <p className={style.section_sub_title}>
              Meine bestehenden Telefonnummern
            </p>
            <Message
              messageType={MessageType.INFO}
              icon={<InfoIconComponent />}
            >
              Die Anzahl an Feldern, die Sie hier sehen, repräsentiert Ihre
              gewählte Anzahl an Telefonnummern im vorherigen Schritt. Sollten
              Sie die Anzahl noch mal anpassen wollen, kehren Sie bitte dorthin
              zurück. Telefonnummern, die Sie hier eintragen, entsprechen
              Rufnummern, die wir von Ihrem Altanbieter zu uns portieren. Für
              Felder, die Sie leer lassen, erhalten Sie jeweils eine neue
              Rufnummer.
            </Message>
            <Row gutter={12}>
              <Col xs={8} sm={6} className={style.country_code_sm}>
                <FloatingLabelInput
                  name="countryCode"
                  label="Länder-Code"
                  value={GERMANY_COUNTRY_CODE}
                  disabled
                />
              </Col>
              <Col xs={16} sm={16} className={style.area_code_sm}>
                <FloatingLabelInput
                  name="areaCode"
                  label="Vorwahl"
                  value={existingPhoneNumberFields[0].areaCode}
                  type="number"
                  onChangeHandler={(e: any) => {
                    handleAreaCodeChange(e.target.value);
                  }}
                />
              </Col>
            </Row>

            {existingPhoneNumberFields.map((field, index) => (
              <Row gutter={12} key={index}>
                <Col span={4} className={style.country_code}>
                  <FloatingLabelInput
                    name="countryCode"
                    label="Länder-Code"
                    value={GERMANY_COUNTRY_CODE}
                    onChangeHandler={(e: any) =>
                      handleExistingPhoneFieldsChange(
                        index,
                        'countryCode',
                        e.target.value
                      )
                    }
                    disabled
                  />
                </Col>
                <Col span={4} className={style.area_code}>
                  <FloatingLabelInput
                    name="areaCode"
                    label="Vorwahl"
                    value={field.areaCode}
                    onChangeHandler={(e: any) => {
                      handleAreaCodeChange(e.target.value);
                    }}
                    disabled={index !== 0}
                    errorStatus={
                      index === 0 &&
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.areaCode
                        ? 'error'
                        : ''
                    }
                    errorMessage={
                      index === 0 &&
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.areaCode
                    }
                  />
                </Col>
                <Col span={24} sm={16}>
                  <FloatingLabelInput
                    name="phoneNumber"
                    label={`Telefonnummer ${index + 1}`}
                    value={field.phoneNumber}
                    onChangeHandler={(e: any) =>
                      handleExistingPhoneFieldsChange(
                        index,
                        'phoneNumber',
                        e.target.value
                      )
                    }
                    errorStatus={
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.phoneNumber
                        ? 'error'
                        : ''
                    }
                    errorMessage={
                      errors &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`] &&
                      // @ts-expect-error
                      errors[`existingPhoneNumbers[${index}]`]?.phoneNumber
                    }
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
      </>
    </div>
  );
};

export default ExistingConnection;
