import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Col, Divider, Row } from 'antd';

import CheckIcon from 'assets/images/svg/check_icon.svg';
import { formatPrice } from '../utils';
import { OrderSummaryLineProps } from './interface';
import { SHIPPING_PRODUCT_ID } from 'constants/Constants';
import { calculatePrice } from 'utils/Utils';
import { ServiceType } from 'store/slices/selectedServices';
import { RootState } from 'store/store';

const OrderSummaryLine: React.FC<OrderSummaryLineProps> = ({
  service,
  amount
}) => {
  const servicesData: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const getServicePrice = (month?: number) => {
    const price = calculatePrice(service, amount, true, month);

    if (!service?.monthly_billing) {
      return `${formatPrice(price)} €`;
    }

    return `${formatPrice(price)} € / Monat`;
  };

  const getAmountIncluded = useCallback(() => {
    if ((service.depends_on_products?.length ?? 0) > 0) {
      const includedService = servicesData.find(
        (selectedService) =>
          selectedService.service.productID === -service.productID
      );
      return includedService?.amount;
    } else {
      return service.amount_included;
    }
  }, []);

  return (
    <Row align="middle">
      <Col span={16}>
        <Row>
          <Col xs={0} lg={2}>
            <img src={CheckIcon} alt="check_icon" />
          </Col>
          <Col span={20}>
            {service?.productID !== SHIPPING_PRODUCT_ID ? (
              <p className="package_name">
                {amount > 1 ? `${amount}x ${service?.name}` : service?.name}
              </p>
            ) : (
              <p className="package_name">{service?.name}</p>
            )}
          </Col>
        </Row>
        {(service.amount_included ?? 0) > 0 && (
          <Row>
            <Col xs={0} lg={2}></Col>
            <Col span={20}>
              <span>
                Inkl. {getAmountIncluded()}x {service.name} enthalten
              </span>
            </Col>
          </Row>
        )}
      </Col>
      <Col span={8}>
        <Row>
          <Col span={24}>
            <p className="package_price">{getServicePrice()}</p>
          </Col>

          {service?.offer_duration ? (
            <Col span={24}>
              <p className="shipping_price_label">
                Ab dem {service?.offer_duration + 1}. Monat{' '}
                <b>{getServicePrice(service?.offer_duration + 1)}</b>
              </p>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Divider />
    </Row>
  );
};

export default OrderSummaryLine;
