import { MessageType } from './interface';
import style from './style.module.css';

export const getMessageClass = (messageType: MessageType) => {
  switch (messageType) {
    case MessageType.INFO:
      return style.message_container_info;
    case MessageType.WARNING:
      return style.message_container_warning;
    case MessageType.ERROR:
      return style.message_container_error;
    case MessageType.SUCCESS:
      return style.message_container_success;
    default:
      return '';
  }
};
