import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectedPersonalInfo,
  selectedTermsAndConditionsAgreements,
  setTermsAndConditionsAgreements,
  selectedCharity,
  selectedClientAddressData
} from 'store/slices/selectedDataSlice';
import { termsAndConditions } from 'store/slices/validationSlice';
import { ServiceType } from 'store/slices/selectedServices';

import { isMobile } from 'store/slices/mobileSlice';

import { ClientAddressData, Packages, PersonalInfo } from 'types/bundle';
import { RootState } from 'store/store';

import { Col, Divider, Row } from 'antd';

import { ReactComponent as CustomerGroupIcon } from 'assets/images/svg/icon_client_group.svg';
import BasketCard from 'components/Basket/BasketCard';
import PaymentAndUserInfo from './components/Payment&UserInfo';
import TransferringInfo from './components/TransferringInfo';
import DonationInfo from './components/DonationInfo';

import { formatAddress } from '../utils';

import style from './style.module.css';
import OrderSummaryLine from './OrderSummaryLine';
import {
  firstNameLastNameExists,
  formatName,
  getTaxInfoMessage,
  mergeProducts,
  sortProductsByCategoryId
} from 'utils/Utils';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import TermsAndConditions from './TermsAndConditions';
import OrderSummaryPDF from './components/OrderSummaryPDF';

const OrderSummary = () => {
  const dispatch = useDispatch();

  const componentRef = useRef<HTMLDivElement | null>(null);

  const isMobileDevice = useSelector(isMobile);

  const servicesData: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const {
    birth_date,
    email,
    first_name,
    last_name,
    phone_number,
    street,
    house_number,
    house_suffix,
    post_code,
    location,
    title,
    company
  }: PersonalInfo = useSelector(selectedPersonalInfo);

  const termsAndConditionsAgreements = useSelector(
    selectedTermsAndConditionsAgreements
  );
  const errors = useSelector(termsAndConditions);

  const charityForDonation = useSelector(selectedCharity);

  const clientAddressData: ClientAddressData = useSelector(
    selectedClientAddressData
  );

  const groupId = `group_${currentPackage?.customer_group_id}`;

  const handleCheckboxChange = (type: string) => (e: CheckboxChangeEvent) => {
    dispatch(
      setTermsAndConditionsAgreements({
        ...termsAndConditionsAgreements,
        [type]: e.target.checked
      })
    );
  };

  const monthlyPaymentServices = mergeProducts(
    servicesData.filter(
      (product: ServiceType) => product?.service?.monthly_billing === true
    )
  ).sort(sortProductsByCategoryId);

  const oneTimePaymentServices = mergeProducts(
    servicesData.filter(
      (product: ServiceType) =>
        product?.service?.monthly_billing === false &&
        product?.service?.group > 0
    )
  ).sort(sortProductsByCategoryId);

  const services = [...monthlyPaymentServices, ...oneTimePaymentServices];

  useEffect(() => {
    const firstErrorCheckbox = Object.keys(errors).find(
      (key) => errors[key] && componentRef.current
    );

    if (firstErrorCheckbox && componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors, componentRef.current]);

  const greetingMessageUser = firstNameLastNameExists(
    title,
    first_name,
    last_name
  )
    ? `${first_name} ${last_name}`
    : company;

  return (
    <div className={style.order_summary_container}>
      <h2 className={style.summary_user_name}>
        {`Hallo ${greetingMessageUser}, hier ist Ihre Bestellzusammenfassung.`}
      </h2>

      <div className="package_card_wrapper">
        <Row>
          <Col span={24}>
            <Row className={style.user_group_wrapper}>
              <Col>
                <p className={style.user_group}>
                  <CustomerGroupIcon /> {currentPackage?.customer_group_title}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row align="middle" className={style.user_info_wrapper}>
          <Col span={24}>
            <h3>Auftraggeber</h3>
          </Col>
          <Col span={24}>
            {company && (
              <Col span={24}>
                <p className={style.user_title}>{company}</p>
              </Col>
            )}

            {firstNameLastNameExists(title, first_name, last_name) && (
              <Col span={24}>
                <p className={style.user_title}>
                  {formatName(title, first_name, last_name)}
                </p>
              </Col>
            )}

            <Col span={24}>
              <p className={style.user_additional_info_text}>
                {[email, phone_number, birth_date && `geb. ${birth_date}`]
                  .filter(Boolean)
                  .join(', ')}
              </p>
            </Col>

            {!clientAddressData?.equalToCustomer && (
              <Col span={24}>
                <p className={style.user_additional_info_text}>
                  {formatAddress(
                    clientAddressData?.street || '',
                    clientAddressData?.house_number || '',
                    clientAddressData?.house_suffix || '',
                    clientAddressData?.post_code || '',
                    clientAddressData?.location || ''
                  )}
                </p>
              </Col>
            )}
            <Col span={24}>
              <p className={style.user_additional_info_text}>
                {!clientAddressData.equalToCustomer
                  ? 'Installationsanschrift: '
                  : ' '}
                {formatAddress(
                  street,
                  house_number,
                  house_suffix,
                  post_code,
                  location
                )}
              </p>
            </Col>
          </Col>
        </Row>

        <Divider className={style.mobile_divider} />

        <Divider />
        <PaymentAndUserInfo />
        <Divider />
        <>
          <TransferringInfo />
          <Divider />
          <h2 className={style.transfer_info_title}>
            Ihre gebuchten Services:
          </h2>
        </>
        {charityForDonation !== '' && (
          <>
            <DonationInfo />
            <Divider />
          </>
        )}
        {services?.map(({ service, amount }, index: number) => (
          <OrderSummaryLine key={index} service={service} amount={amount} />
        ))}
        <p className={style.disclaimer}>
          {getTaxInfoMessage(currentPackage?.customer_group_net_prices)}
        </p>
      </div>

      {isMobileDevice && <BasketCard fullScreen={false} />}

      <Divider />

      <TermsAndConditions
        ref={componentRef}
        groupId={groupId}
        termsAndConditionsAgreements={termsAndConditionsAgreements}
        handleCheckboxChange={handleCheckboxChange}
        errors={errors}
      />
      <OrderSummaryPDF />
    </div>
  );
};

export default OrderSummary;
