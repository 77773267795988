import { ServiceType } from 'store/slices/selectedServices';
import { calculatePrice, mergeProducts } from 'utils/Utils';

export const calculatePriceChanges = (products: ServiceType[]) => {
  const totalDuration = 25;
  const priceChanges = [];

  let prevMonthPrice = null;

  const mergedProducts = mergeProducts(products);

  for (let month = 0; month < totalDuration; month++) {
    let price = 0;
    const productsByMonth = [];

    for (const product of mergedProducts) {
      if (product?.service?.monthly_billing && product?.service?.group > 0) {
        const servicePrice = calculatePrice(
          product?.service,
          product?.amount,
          true,
          month
        );

        price += servicePrice;
        productsByMonth.push({
          product: product?.service,
          amount: product?.amount,
          productPrice: servicePrice
        });
      }
    }
    if (price !== prevMonthPrice) {
      priceChanges.push({
        month: month + 1,
        price,
        productsByMonth
      });
    }

    prevMonthPrice = price;
  }

  return priceChanges;
};
