const HOSTNAME = window && window.location && window.location.hostname;
const PROTOCOL = window && window.location && window.location.protocol;

let API_URL = '';

if (process.env.NODE_ENV === 'development') {
  API_URL = `${process.env.REACT_APP_PROXY_TARGET}`;
} else {
  API_URL = `${PROTOCOL}//${HOSTNAME}/api`;
}

export { API_URL };
