import { MessageProps } from './interface';
import style from './style.module.css';
import { getMessageClass } from './utils';

const Message = ({ messageType, icon, className, children }: MessageProps) => {
  const messageTypeClass = getMessageClass(messageType);

  return (
    <div
      className={`${style.message_container} ${messageTypeClass} ${className ? className : ''}`}
    >
      {icon && <span className={style.message_icon}>{icon}</span>}
      <span className={style.message_text}>{children}</span>
    </div>
  );
};

export default Message;
