import { createSlice } from '@reduxjs/toolkit';

import {
  Products,
  ExistingConnectionData,
  PersonalInfo,
  PaymentData,
  ShippingData,
  PropertyOwnerData,
  ContactPersonData,
  InvoiceData,
  PhoneBook,
  AgentFilesData,
  ClientAddressData
} from 'types/bundle';
import { TermsAndConditionsAgreements } from 'components/Step/OrderSummary/termsAndConditionsValidationSchema';

export interface InitialState {
  packageType: string;
  packageTypeProduct: Products;
  selectedExistingConnection: ExistingConnectionData;
  phoneBook: PhoneBook;
  personalInfo: PersonalInfo;
  clientAddressData: ClientAddressData;
  paymentData: PaymentData;
  shippingData: ShippingData;
  propertyOwnerData: PropertyOwnerData;
  contactPersonData: ContactPersonData;
  invoiceData: InvoiceData;
  selectedCharity: string;
  shippingProduct: Products;
  termsAndConditionsAgreements: TermsAndConditionsAgreements;
  agentFilesData: AgentFilesData;
  constructionPermitAgreementFileIds: string[];
}

const selectedDataSlice = createSlice({
  name: 'selectedData',
  initialState: {
    packageType: '',
    packageTypeProduct: {},
    selectedExistingConnection: {
      cancelMyCurrentPlan: false,
      portMyCurrentPhoneNumber: false,
      previousProvider: '',
      currentConnectionOwner: {
        equalToCustomer: true,
        title: '',
        name: '',
        lastName: '',
        company: ''
      },
      existingPhoneNumber: [
        {
          countryCode: '+49',
          areaCode: '',
          phoneNumber: ''
        },
        {
          countryCode: '+49',
          areaCode: '',
          phoneNumber: ''
        },
        {
          countryCode: '+49',
          areaCode: '',
          phoneNumber: ''
        }
      ]
    },
    phoneBook: {
      phoneBookEntry: false,
      phoneBookOnlineEntry: false,
      phoneBookAddress: false,
      phoneBookInverseSearch: false,
      phoneBookNumberEntryOption: 0
    },
    personalInfo: {
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      birth_date: '',
      phone_number: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: ''
    },
    clientAddressData: {
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      equalToCustomer: true
    },
    paymentData: {
      iban: '',
      bic: '',
      credit_institution: '',
      account_owner_title: '',
      account_owner_name: '',
      account_owner_last_name: '',
      account_owner_company: '',
      accept_payment_terms: false,
      valid: false,
      equalToCustomer: true,
      manuallyTyped: false
    },
    shippingData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: ''
    },

    invoiceData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: ''
    },
    propertyOwnerData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      street: '',
      house_number: '',
      house_suffix: '',
      post_code: '',
      location: '',
      company: '',
      residentalUnitsCount: '',
      permission_contract: 0
    },
    contactPersonData: {
      equalToCustomer: true,
      title: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: ''
    },
    selectedCharity: '',
    shippingProduct: {},
    termsAndConditionsAgreements: {
      agbTermsAgreement: false,
      confirmationDlAndRevokeProductsAgreement: false,
      confirmationSummaryOfServices: false
    },
    agentFilesData: {
      agentId: '',
      uploadedFiles: [],
      salesChannel: ''
    },
    constructionPermitAgreementFileIds: [] as string[]
  } as InitialState,

  reducers: {
    setPackageType: (state, action) => {
      state.packageType = action.payload;
    },

    setPackageTypeProduct: (state, action) => {
      state.packageTypeProduct = action.payload;
    },
    setExistingConnection: (state, action) => {
      state.selectedExistingConnection = action.payload;
    },
    setExistingConnectionOwnerOptions: (state, action) => {
      state.selectedExistingConnection.currentConnectionOwner = action.payload;
    },
    setExistingConnectionPhoneNumbers: (state, action) => {
      state.selectedExistingConnection.existingPhoneNumber = action.payload;
    },

    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload;
    },
    setClientAddressData: (state, action) => {
      state.clientAddressData = action.payload;
    },
    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
    },
    setShippingData: (state, action) => {
      state.shippingData = action.payload;
    },
    setInvoiceData: (state, action) => {
      state.invoiceData = action.payload;
    },
    setPropertyOwnerData: (state, action) => {
      state.propertyOwnerData = action.payload;
    },
    setContactPersonData: (state, action) => {
      state.contactPersonData = action.payload;
    },
    setCharity: (state, action) => {
      state.selectedCharity = action.payload;
    },
    setShippingProduct: (state, action) => {
      state.shippingProduct = action.payload;
    },
    setTermsAndConditionsAgreements: (state, action) => {
      state.termsAndConditionsAgreements = action.payload;
    },
    setPhoneBook: (state, action) => {
      state.phoneBook = action.payload;
    },
    setAgentFilesData: (state, action) => {
      state.agentFilesData = action.payload;
    },
    setConstructionPermitAgreementFileIds: (state, action) => {
      state.constructionPermitAgreementFileIds = action.payload;
    }
  }
});

export const {
  setPackageType,
  setPackageTypeProduct,
  setExistingConnection,
  setExistingConnectionOwnerOptions,
  setExistingConnectionPhoneNumbers,
  setPersonalInfo,
  setClientAddressData,
  setPaymentData,
  setShippingData,
  setInvoiceData,
  setPropertyOwnerData,
  setContactPersonData,
  setCharity,
  setShippingProduct,
  setTermsAndConditionsAgreements,
  setPhoneBook,
  setAgentFilesData,
  setConstructionPermitAgreementFileIds
} = selectedDataSlice.actions;

export const selectedPackageType = (state: { selectedData: InitialState }) =>
  state.selectedData.packageType;

export const selectedPackageTypeProduct = (state: {
  selectedData: InitialState;
}) => state.selectedData.packageTypeProduct;

export const selectedExistingConnection = (state: {
  selectedData: InitialState;
}) => state.selectedData.selectedExistingConnection;

export const selectedPersonalInfo = (state: { selectedData: InitialState }) =>
  state.selectedData.personalInfo;

export const selectedClientAddressData = (state: {
  selectedData: InitialState;
}) => state.selectedData.clientAddressData;

export const selectedPaymentData = (state: { selectedData: InitialState }) =>
  state.selectedData.paymentData;

export const selectedShippingData = (state: { selectedData: InitialState }) =>
  state.selectedData.shippingData;

export const selectedInvoiceData = (state: { selectedData: InitialState }) =>
  state.selectedData.invoiceData;

export const selectedPropertyOwnerData = (state: {
  selectedData: InitialState;
}) => state.selectedData.propertyOwnerData;

export const selectedContactPersonData = (state: {
  selectedData: InitialState;
}) => state.selectedData.contactPersonData;

export const selectedCharity = (state: { selectedData: InitialState }) =>
  state.selectedData.selectedCharity;

export const selectedShippingProduct = (state: {
  selectedData: InitialState;
}) => state.selectedData.shippingProduct;

export const selectedTermsAndConditionsAgreements = (state: {
  selectedData: InitialState;
}) => state.selectedData.termsAndConditionsAgreements;

export const selectedAgentFilesData = (state: { selectedData: InitialState }) =>
  state.selectedData.agentFilesData;

export const selectedPhoneBook = (state: { selectedData: InitialState }) =>
  state.selectedData.phoneBook;

export default selectedDataSlice.reducer;
