import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { calculatePriceChanges } from './utils';
import { formatPrice } from '../Step/utils';

import { RootState } from 'store/store';
import {
  selectedPackageType,
  selectedPackageTypeProduct
} from 'store/slices/selectedDataSlice';

import { isMobile, setShowMobileBasket } from 'store/slices/mobileSlice';

import { setOneTimePrice, setMonthlyPrice } from 'store/slices/pricesSlice';

import { Packages } from 'types/bundle';
import { BasketItem, PackageCardProps } from './interface';
import { ServiceType } from 'store/slices/selectedServices';

import { Divider, Row, Col, Collapse } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ExpandIconOpened, ExpandIconClosed } from 'assets/Icons';

import style from './style.module.css';
import 'styles/global.css';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import { calculatePrice, getTaxInfoMessage } from 'utils/Utils';

const { Panel } = Collapse;

const BasketCard = ({ fullScreen, currentStep }: PackageCardProps) => {
  const dispatch = useDispatch();

  const packageType = useSelector(selectedPackageType);
  const isMobileSize = useSelector(isMobile);
  const selectedPackageProduct = useSelector(selectedPackageTypeProduct);

  const servicesProducts: any = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const [oneTimeServices, setOneTimeServices] = useState<BasketItem[]>([]);

  const { selectedExistingConnection } = useSelector(
    (state: RootState) => state.selectedData
  );

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const [monthlyPrices, setMonthlyPrices] = useState<
    {
      month: number;
      price: number;
      productsByMonth: BasketItem[];
    }[]
  >([]);

  const oneTimePrice = useSelector(
    (state: RootState) => state.prices.oneTimePrice
  );

  useEffect(() => {
    let sumOneTime = 0;
    const oneTimePaymentServices: BasketItem[] = [];

    const calculateOneTimePrice = (product: ServiceType, amount: number) => {
      if (product?.service?.productID && product?.service?.group > 0) {
        if (!product.service.monthly_billing) {
          const productPrice = calculatePrice(product?.service, amount, true);

          sumOneTime += productPrice;
          oneTimePaymentServices.push({
            product: product?.service,
            amount,
            productPrice
          });
        }
      }
    };

    servicesProducts.forEach((product: ServiceType) => {
      calculateOneTimePrice(product, product.amount);
    });

    setOneTimeServices(oneTimePaymentServices);

    const formattedOneTimePrice = sumOneTime.toFixed(2).replace('.', ',');

    dispatch(setOneTimePrice(formattedOneTimePrice));
  }, [servicesProducts, dispatch]);

  useEffect(() => {
    const newPriceChanges = calculatePriceChanges(servicesProducts);

    setMonthlyPrices(newPriceChanges);
    dispatch(setMonthlyPrice(newPriceChanges));
  }, [servicesProducts, dispatch, selectedExistingConnection]);

  return (
    <div
      className={
        fullScreen
          ? `${style.basket_card_wrapper_full_screen} ${style.test}`
          : `${style.basket_card_wrapper} ${(currentStep === 0 || currentStep === 4) && style.margin_top}`
      }
    >
      {isMobileSize && fullScreen && (
        <Row justify={'end'}>
          <Col span={1}>
            <CloseOutlined
              onClick={() => dispatch(setShowMobileBasket(false))}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <h2 className={style.basket_title}>Warenkorb:</h2>
        </Col>
      </Row>
      <p className={style.package_speed}>{`MUENET ${
        packageType?.toUpperCase() || ''
      } ${selectedPackageProduct?.download_rate || ''}/${
        selectedPackageProduct?.upload_rate || ''
      } `}</p>
      <Divider />
      {monthlyPrices.map((change, index) => {
        return (
          <Collapse
            accordion
            ghost
            bordered={true}
            expandIcon={({ isActive }) =>
              isActive ? <ExpandIconOpened /> : <ExpandIconClosed />
            }
            expandIconPosition="end"
            activeKey={undefined}
            key={index}
          >
            <Panel
              key={index}
              header={
                <div key={index} className={style.price_wrapper}>
                  <div className={style.monthly_label_wrapper}>
                    {index === 0 && (
                      <p className={style.monthly_label}>Monatlich:</p>
                    )}
                    <p className={style.monthly_label_duration}>
                      {`Ab dem ${change.month}. Monat`}
                    </p>
                  </div>
                  <p
                    className={
                      index === 0 ? style.monthly_price : style.monthly_price_sm
                    }
                  >
                    {`${change.price.toLocaleString('de-DE', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })} € /Monat`}
                  </p>
                </div>
              }
            >
              {change?.productsByMonth.map((monthlyProducts) => {
                return (
                  <Row
                    key={monthlyProducts?.product?.productID}
                    justify={'space-between'}
                    className="margin_bottom_sm"
                  >
                    <Col>
                      <p className={style.monthly_label_duration}>
                        {monthlyProducts?.product?.name ??
                          'Zusätzliche Telefonnummer'}
                      </p>
                    </Col>
                    <Col>
                      <p className={style.monthly_price_sm}>
                        {`${formatPrice(monthlyProducts?.productPrice)} €`}
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </Panel>
          </Collapse>
        );
      })}
      <Collapse
        accordion
        ghost
        bordered={true}
        expandIcon={({ isActive }) =>
          isActive ? <ExpandIconOpened /> : <ExpandIconClosed />
        }
        expandIconPosition="end"
        activeKey={undefined}
      >
        <Panel
          header={
            <div className={style.price_wrapper}>
              <p className={style.one_time_payment_label}>Einmalig:</p>
              <p className={style.one_time_price}>{`${oneTimePrice} €`}</p>
            </div>
          }
          key={0}
        >
          {oneTimeServices.map((serviceProduct: BasketItem) => (
            <Row
              key={serviceProduct?.product?.productID}
              justify={'space-between'}
              className="margin_bottom_sm"
            >
              <Col>
                <p className={style.monthly_label_duration}>
                  {serviceProduct?.product?.name}
                </p>
              </Col>
              <Col>
                <p className={style.monthly_price_sm}>
                  {`${formatPrice(serviceProduct?.productPrice)} €`}
                </p>
              </Col>
            </Row>
          ))}
        </Panel>
      </Collapse>

      <p className={style.tax_disclaimer}>
        {getTaxInfoMessage(currentPackage?.customer_group_net_prices)}
      </p>
    </div>
  );
};

export default BasketCard;
