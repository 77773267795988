import { InitialState } from 'store/slices/selectedDataSlice';
import { Packages, PersonalInfo, Products } from 'types/bundle';
import { formatPrice } from 'components/Step/utils';
import { ServiceType } from 'store/slices/selectedServices';
import {
  calculatePrice,
  mergeProducts,
  sortProductsByCategoryId
} from 'utils/Utils';
import { TITLE_NOT_SELECTED } from 'constants/Constants';

export const generateFinalSubmitData = (
  selectedData: InitialState,
  priceState: any,
  applicantStreet: string,
  applicantTown: string,
  applicantHouseNumber: string,
  applicantSuffix: string,
  applicantPostCode: string,
  kvRegionID: string,
  projectArID: string,
  addressID: string,
  hashkey: string,
  sessionID: string,
  packageType: string,
  paymentRequired: boolean,
  servicesData: ServiceType[],
  currentPackage: Packages
) => {
  const {
    personalInfo,
    clientAddressData,
    paymentData,
    selectedExistingConnection,
    shippingData,
    invoiceData,
    contactPersonData,
    propertyOwnerData,
    selectedCharity,
    phoneBook,
    agentFilesData,
    constructionPermitAgreementFileIds
  }: InitialState = selectedData;

  const { oneTimePrice, monthlyPrice } = priceState;

  const selectedServicesList = () => {
    const basketProductsSummary = monthlyPrice.map((price: any) => ({
      pricingType: `Monatlich: Ab dem ${price.month}. Monat`,
      price: `${formatPrice(price.price)}`
    }));

    basketProductsSummary.push({
      pricingType: 'Einmalig',
      price: `${formatPrice(oneTimePrice)}`
    });

    const productsList: any = [];

    const orderSummaryProductList: any = [];

    const getPriceOffered = (product: Products, amount: number) => {
      let priceOffered = '';

      if (product?.offer_duration !== 0) {
        priceOffered = priceOffered.concat(
          `${formatPrice(calculatePrice(product, amount, true))} €`
        );
        if (product?.monthly_billing) {
          priceOffered = priceOffered.concat(` / Monat`);
        }
      }

      return priceOffered;
    };

    const getStandardPrice = (product: Products, amount: number) => {
      let priceStandard = '';

      if (product?.offer_duration !== 0) {
        priceStandard = priceStandard.concat(
          `Ab dem ${product?.offer_duration + 1}. Monat  ${formatPrice(
            calculatePrice(product, amount, true, product?.offer_duration + 1)
          )} €`
        );
        if (product?.monthly_billing) {
          priceStandard = priceStandard.concat(` / Monat`);
        }
      } else {
        priceStandard = priceStandard.concat(
          ` ${formatPrice(calculatePrice(product, amount, true))} €`
        );
        if (product?.monthly_billing) {
          priceStandard = priceStandard.concat(` / Monat`);
        }
      }

      return priceStandard;
    };

    if (servicesData.length > 0) {
      const monthlyPaymentServices = mergeProducts(
        servicesData.filter(
          (product: ServiceType) => product?.service?.monthly_billing === true
        )
      ).sort(sortProductsByCategoryId);

      const oneTimePaymentServices = mergeProducts(
        servicesData.filter(
          (product: ServiceType) =>
            product?.service?.monthly_billing === false &&
            product?.service?.group > 0
        )
      ).sort(sortProductsByCategoryId);

      // TODO: Create a function for orderSummaryProductList populate so that we do not have
      //       duplicate code for looping through out monthlyPayments and oneTimePayments
      monthlyPaymentServices?.map((serviceProducts: ServiceType) => {
        if (shouldIncludeProduct(serviceProducts)) {
          productsList.push({
            kvId: serviceProducts?.service?.productID,
            arId: null,
            selected: 1,
            quantity: getSubmitQuantity(servicesData, serviceProducts),
            remarks: ''
          });
        }

        orderSummaryProductList.push({
          serviceName: serviceProducts?.service?.name,
          descriptionLink: serviceProducts?.service?.description_link,
          priceStandard: getStandardPrice(
            serviceProducts?.service,
            serviceProducts?.amount
          ),
          priceOffered:
            serviceProducts?.service?.price_offer !== undefined &&
            serviceProducts?.service?.price_offer !== null
              ? getPriceOffered(
                  serviceProducts?.service,
                  serviceProducts?.amount
                )
              : null,
          quantity: serviceProducts.amount,
          quantityIncluded: getPDFAmountIncluded(servicesData, serviceProducts),
          downloadRate: serviceProducts?.service?.download_rate,
          uploadRate: serviceProducts?.service?.upload_rate
        });
      });

      oneTimePaymentServices?.map((serviceProducts: ServiceType) => {
        if (shouldIncludeProduct(serviceProducts)) {
          productsList.push({
            kvId: serviceProducts?.service?.productID,
            arId: null,
            selected: 1,
            quantity: getSubmitQuantity(servicesData, serviceProducts),
            remarks: ''
          });
        }

        orderSummaryProductList.push({
          serviceName: serviceProducts?.service?.name,
          priceStandard: getStandardPrice(
            serviceProducts?.service,
            serviceProducts?.amount
          ),
          priceOffered: serviceProducts?.service?.price_offer
            ? getPriceOffered(serviceProducts?.service, serviceProducts?.amount)
            : null,
          quantity: serviceProducts.amount,
          quantityIncluded: getPDFAmountIncluded(servicesData, serviceProducts)
        });
      });
    }

    return { productsList, orderSummaryProductList, basketProductsSummary };
  };

  const submitData = {
    servicePackage: packageType,
    customerGroupFirm: currentPackage?.customer_group_firm || false,
    customerGroupId: currentPackage?.customer_group_id || 1,
    hashkey: hashkey,
    session_id: sessionID,
    projectKvId: kvRegionID,
    projectArId: projectArID,
    contractType: 'EFH',
    agentId: agentFilesData.agentId,
    uploadedFiles: [
      ...agentFilesData.uploadedFiles,
      ...constructionPermitAgreementFileIds
    ],
    salesChannel: agentFilesData.salesChannel,
    customerAddress: {
      postcode: applicantPostCode,
      town: applicantTown,
      street: applicantStreet,
      houseNumber: applicantHouseNumber,
      suffix: applicantSuffix || null,
      addressId: addressID
    },
    customerContactData: {
      salutation: getTitleOrNull(personalInfo, personalInfo, true),
      name: personalInfo.first_name ? personalInfo.first_name : null,
      surname: personalInfo.last_name ? personalInfo.last_name : null,
      dob: personalInfo.birth_date ? personalInfo.birth_date : null,
      telephone: personalInfo.phone_number,
      email: personalInfo.email !== '' ? personalInfo.email : null,
      company: personalInfo.company ? personalInfo.company : null
    },
    donationOrganization: selectedCharity,
    services: selectedServicesList().productsList,
    bankData: {
      iban: paymentRequired ? paymentData.iban : null,
      bic: paymentRequired ? paymentData.bic : null,
      bankName: paymentRequired ? paymentData.credit_institution : null,
      accountOwnerName: paymentRequired
        ? paymentData.equalToCustomer
          ? personalInfo.first_name
            ? personalInfo.first_name
            : null
          : paymentData.account_owner_name
            ? paymentData.account_owner_name
            : null
        : null,
      accountOwnerSurname: paymentRequired
        ? paymentData.equalToCustomer
          ? personalInfo.last_name
            ? personalInfo.last_name
            : null
          : paymentData.account_owner_last_name
            ? paymentData.account_owner_last_name
            : null
        : null,
      accountOwnerCompany: paymentRequired
        ? paymentData.equalToCustomer
          ? personalInfo.company
            ? personalInfo.company
            : null
          : paymentData.account_owner_company
            ? paymentData.account_owner_company
            : null
        : null,
      salutation: paymentRequired
        ? getTitleOrNull(
            { title: paymentData.account_owner_title },
            personalInfo,
            paymentData.equalToCustomer
          )
        : null,
      sepaAgreement: true,
      bankPaymentType: paymentRequired ? 0 : 1,
      equalToCustomer: paymentRequired ? paymentData?.equalToCustomer : null
    },
    agreements: {
      termsAndConditions: true,
      contractRevocation: true,
      servicesRevocation: true,
      serviceSummary: true
    },
    clientAddress: {
      equalToCustomer: clientAddressData?.equalToCustomer,
      address: {
        postcode: clientAddressData?.post_code,
        town: clientAddressData?.location,
        street: clientAddressData?.street,
        houseNumber: clientAddressData?.house_number,
        suffix: clientAddressData?.house_suffix || null
      }
    },
    telephoneData: {
      currentSupplier:
        selectedExistingConnection.cancelMyCurrentPlan ||
        selectedExistingConnection.portMyCurrentPhoneNumber
          ? selectedExistingConnection.previousProvider
          : null,
      salutation: getTitleOrNull(
        selectedExistingConnection?.currentConnectionOwner,
        personalInfo,
        selectedExistingConnection?.currentConnectionOwner?.equalToCustomer ==
          true
      ),
      name: selectedExistingConnection.currentConnectionOwner.equalToCustomer
        ? personalInfo.first_name
          ? personalInfo.first_name
          : null
        : selectedExistingConnection.currentConnectionOwner.name
          ? selectedExistingConnection.currentConnectionOwner.name
          : null,
      surname: selectedExistingConnection.currentConnectionOwner.equalToCustomer
        ? personalInfo.last_name
          ? personalInfo.last_name
          : null
        : selectedExistingConnection.currentConnectionOwner.lastName
          ? selectedExistingConnection.currentConnectionOwner.lastName
          : null,
      company: selectedExistingConnection.currentConnectionOwner.equalToCustomer
        ? personalInfo.company
          ? personalInfo.company
          : null
        : selectedExistingConnection.currentConnectionOwner.company
          ? selectedExistingConnection.currentConnectionOwner.company
          : null,
      areaCode:
        selectedExistingConnection.existingPhoneNumber[0].areaCode || null,
      phoneNumbers:
        selectedExistingConnection.existingPhoneNumber.map(
          (phone) => phone.phoneNumber
        ) || null,
      cancellationByMn: selectedExistingConnection.cancelMyCurrentPlan,
      portingDoneByMn: selectedExistingConnection.portMyCurrentPhoneNumber,
      equalToCustomer:
        selectedExistingConnection?.cancelMyCurrentPlan ||
        selectedExistingConnection?.portMyCurrentPhoneNumber
          ? selectedExistingConnection?.currentConnectionOwner?.equalToCustomer
          : null
    },
    permissionAgreement: {
      equalToCustomer: propertyOwnerData.equalToCustomer,
      address: {
        postcode: !propertyOwnerData.equalToCustomer
          ? propertyOwnerData.post_code
          : applicantPostCode,
        town: !propertyOwnerData.equalToCustomer
          ? propertyOwnerData.location
          : applicantTown,
        street: !propertyOwnerData.equalToCustomer
          ? propertyOwnerData.street
          : applicantStreet,
        houseNumber: !propertyOwnerData.equalToCustomer
          ? propertyOwnerData.house_number
          : applicantHouseNumber,
        suffix: !propertyOwnerData.equalToCustomer
          ? propertyOwnerData.house_suffix
          : applicantSuffix || null
      },
      telephone: !propertyOwnerData.equalToCustomer
        ? propertyOwnerData.phone_number
        : personalInfo.phone_number,
      email: !propertyOwnerData.equalToCustomer
        ? propertyOwnerData.email
        : personalInfo.email !== ''
          ? personalInfo.email
          : null,
      name: !propertyOwnerData.equalToCustomer
        ? propertyOwnerData.first_name
          ? propertyOwnerData.first_name
          : null
        : personalInfo.first_name
          ? personalInfo.first_name
          : null,
      surname: !propertyOwnerData.equalToCustomer
        ? propertyOwnerData.last_name
          ? propertyOwnerData.last_name
          : null
        : personalInfo.last_name
          ? personalInfo.last_name
          : null,
      salutation: getTitleOrNull(
        propertyOwnerData,
        personalInfo,
        propertyOwnerData?.equalToCustomer
      ),
      company: !propertyOwnerData.equalToCustomer
        ? propertyOwnerData.company
          ? propertyOwnerData.company
          : null
        : personalInfo.company
          ? personalInfo.company
          : null,
      residentalUnitsCount: propertyOwnerData.residentalUnitsCount,
      permissionContract: propertyOwnerData.permission_contract
    },
    deliveryAddress: {
      equalToCustomer: shippingData.equalToCustomer,
      address: {
        postcode: !shippingData.equalToCustomer
          ? shippingData.post_code
          : applicantPostCode,
        town: !shippingData.equalToCustomer
          ? shippingData.location
          : applicantTown,
        street: !shippingData.equalToCustomer
          ? shippingData.street
          : applicantStreet,
        houseNumber: !shippingData.equalToCustomer
          ? shippingData.house_number
          : applicantHouseNumber,
        suffix: !shippingData.equalToCustomer
          ? shippingData.house_suffix
          : applicantSuffix || null
      },
      name: !shippingData.equalToCustomer
        ? shippingData.first_name
          ? shippingData.first_name
          : null
        : personalInfo.first_name
          ? personalInfo.first_name
          : null,
      surname: !shippingData.equalToCustomer
        ? shippingData.last_name
          ? shippingData.last_name
          : null
        : personalInfo.last_name
          ? personalInfo.last_name
          : null,
      salutation: getTitleOrNull(
        shippingData,
        personalInfo,
        shippingData?.equalToCustomer
      ),
      company: !shippingData.equalToCustomer
        ? shippingData.company
          ? shippingData.company
          : null
        : personalInfo.company
          ? personalInfo.company
          : null
    },
    contactHouseInspection: {
      equalToCustomer: contactPersonData.equalToCustomer,
      telephone: !contactPersonData.equalToCustomer
        ? contactPersonData.phone_number
        : personalInfo.phone_number,
      email: !contactPersonData.equalToCustomer
        ? contactPersonData.email
        : personalInfo.email !== ''
          ? personalInfo.email
          : null,
      name: !contactPersonData.equalToCustomer
        ? contactPersonData.first_name
        : personalInfo.first_name
          ? personalInfo.first_name
          : null,
      surname: !contactPersonData.equalToCustomer
        ? contactPersonData.last_name
        : personalInfo.last_name
          ? personalInfo.last_name
          : null,
      salutation: getTitleOrNull(
        contactPersonData,
        personalInfo,
        contactPersonData?.equalToCustomer
      )
    },
    invoiceAddress: {
      equalToCustomer: invoiceData.equalToCustomer,
      address: {
        postcode: !invoiceData.equalToCustomer
          ? invoiceData.post_code
          : applicantPostCode,
        town: !invoiceData.equalToCustomer
          ? invoiceData.location
          : applicantTown,
        street: !invoiceData.equalToCustomer
          ? invoiceData.street
          : applicantStreet,
        houseNumber: !invoiceData.equalToCustomer
          ? invoiceData.house_number
          : applicantHouseNumber,
        suffix: !invoiceData.equalToCustomer
          ? invoiceData.house_suffix
          : applicantSuffix || null
      },
      email: !invoiceData.equalToCustomer
        ? invoiceData.email
        : personalInfo.email !== ''
          ? personalInfo.email
          : null,
      name: !invoiceData.equalToCustomer
        ? invoiceData.first_name
          ? invoiceData.first_name
          : null
        : personalInfo.first_name
          ? personalInfo.first_name
          : null,
      surname: !invoiceData.equalToCustomer
        ? invoiceData.last_name
          ? invoiceData.last_name
          : null
        : personalInfo.last_name
          ? personalInfo.last_name
          : null,
      salutation: getTitleOrNull(
        invoiceData,
        personalInfo,
        invoiceData?.equalToCustomer
      ),
      company: !invoiceData.equalToCustomer
        ? invoiceData.company
          ? invoiceData.company
          : null
        : personalInfo.company
          ? personalInfo.company
          : null
    },
    orderSummary: {
      customerInfo: {
        salutation: getTitleOrNull(personalInfo, personalInfo, true) || '', // TODO: Check if customerInfo is used on backend
        name: personalInfo.first_name,
        surname: personalInfo.last_name,
        dob: personalInfo.birth_date,
        telephone: personalInfo.phone_number,
        email: personalInfo.email !== '' ? personalInfo.email : null,
        postcode: personalInfo.post_code,
        town: personalInfo.location,
        street: personalInfo.street,
        houseNumber: personalInfo.house_number
      },
      orderedServices: selectedServicesList().orderSummaryProductList,
      total: selectedServicesList().basketProductsSummary
    },
    phoneBook: {
      phoneBookEntry: phoneBook.phoneBookEntry,
      phoneBookOnlineEntry: phoneBook.phoneBookOnlineEntry,
      phoneBookAddress: phoneBook.phoneBookAddress,
      phoneBookInverseSearch: phoneBook.phoneBookInverseSearch,
      phoneBookNumberEntryOption: phoneBook.phoneBookNumberEntryOption
    }
  };

  return { submitData };
};

const getTitleOrNull = <T extends { title?: string | null }>(
  mainData: T,
  personalInfo: PersonalInfo,
  equalToCustomer: boolean
): string | null => {
  const title = equalToCustomer ? personalInfo?.title : mainData?.title;
  return title && title !== TITLE_NOT_SELECTED ? title : null;
};

const shouldIncludeProduct = (service: ServiceType) =>
  service.amount - (service.service.amount_included ?? 0) > 0;

const getSubmitQuantity = (
  servicesData: ServiceType[],
  product: ServiceType
) => {
  if (
    product.service.amount_included &&
    (product.service.depends_on_products?.length ?? 0) > 0
  ) {
    return servicesData.find(
      (service) => service.service.productID === product.service.productID
    )?.amount;
  } else {
    return product.amount - (product.service.amount_included ?? 0);
  }
};

const getPDFAmountIncluded = (
  servicesData: ServiceType[],
  product: ServiceType
) => {
  if (
    product.service.amount_included &&
    (product.service.depends_on_products?.length ?? 0) > 0
  ) {
    return servicesData.find(
      (service) => service.service.productID === -product.service.productID
    )?.amount;
  } else {
    return product.service.amount_included;
  }
};
