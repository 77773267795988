import { ReactNode } from 'react';

export enum MessageType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export interface MessageProps {
  messageType: MessageType;
  icon?: ReactNode;
  className?: string;
  children: ReactNode;
}
