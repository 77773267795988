import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Collapse } from 'antd';

import ExistingConnection from './AddConnection/ExistingConnection';

import {
  ExpandIconOpened,
  ExpandIconClosed,
  ExistingConnectionIcon,
  EntryInTelephoneBookIcon
} from '../../../assets/Icons';

import style from './style.module.css';
import EntryPhoneBook from './EntryPhoneBook/EntryPhoneBook';
import { ServiceType } from 'store/slices/selectedServices';
import { RootState } from 'store/store';
import { ADDITIONAL_PHONE_NUMBER_PRODUCT_ID } from 'constants/Constants';
import ErrorMessage from 'components/ErrorMessage';
import {
  existingConnectionsErrors,
  formIsValid
} from 'store/slices/validationSlice';
import { createValidationErrorMessage } from '../utils';

const ExistingConnectionSection = () => {
  const { Panel } = Collapse;

  const [activeSection, setActiveSection] = useState<string>('1');

  const errors = useSelector(existingConnectionsErrors);

  const formValid = useSelector(formIsValid);

  const selectedProducts: ServiceType[] = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const validationErrorMessage = createValidationErrorMessage(errors);

  const onChange = (key: string | string[]) => {
    setActiveSection(key[0]);

    window.scrollTo({ top: 20, left: 0, behavior: 'auto' });
  };

  const phoneNumberProduct = selectedProducts?.find(
    (prod) => prod?.service?.productID === ADDITIONAL_PHONE_NUMBER_PRODUCT_ID
  );

  return (
    <>
      {!formValid && <ErrorMessage errorMessage={validationErrorMessage} />}
      <Collapse
        onChange={onChange}
        accordion
        ghost
        bordered={true}
        expandIcon={({ isActive }) =>
          isActive ? <ExpandIconOpened /> : <ExpandIconClosed />
        }
        expandIconPosition="end"
        activeKey={activeSection}
      >
        <Panel
          header={
            <div className={style.collapse_header_wrapper}>
              <ExistingConnectionIcon />
              <h2>Bestehender Anschluss</h2>
            </div>
          }
          key="1"
        >
          <ExistingConnection />
        </Panel>

        {phoneNumberProduct && (
          <Panel
            header={
              <div className={style.collapse_header_wrapper}>
                <EntryInTelephoneBookIcon />
                <h2>Eintrag ins Telefonbuch (Optional)</h2>
              </div>
            }
            key="2"
          >
            <EntryPhoneBook />
          </Panel>
        )}
      </Collapse>
    </>
  );
};

export default ExistingConnectionSection;
