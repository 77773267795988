import { useMemo, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { RootState, AppDispatch } from 'store/store';

import { ProductProps } from './interface';

import { isMobile } from 'store/slices/mobileSlice';

import {
  setSelectedServices,
  ServiceType,
  addShippingPrice,
  removeShippingPrice
} from 'store/slices/selectedServices';

import { Col, Divider, Row, Radio, Checkbox, CheckboxProps } from 'antd';
import FloatingSelect from 'components/FloatingLabelSelect';

import style from './style.module.css';
import 'styles/global.css';
import { Products } from 'types/bundle';
import { SHIPPING_PRODUCT_ID } from 'constants/Constants';
import { formatPrice } from 'components/Step/utils';
import { calculatePrice } from 'utils/Utils';

const Product = ({
  product,
  radio,
  checkbox,
  dependantProducts,
  isIncluded,
  isInternetProduct = false
}: ProductProps) => {
  const dispatch: AppDispatch = useDispatch();

  const mobileDevice = useSelector(isMobile);

  const servicesData = useSelector(
    (state: RootState) => state.selectedServices.selectedProducts
  );

  const shippingProduct: Products = useSelector(
    (state: RootState) => state.shippingProduct.shippingProduct
  );

  const selectedPositiveProductData = servicesData.find(
    (prod) => prod?.service?.productID === product?.productID
  );

  const initialAmountIncluded = product?.amount_included;

  const isDependantProduct = useMemo(
    () => product?.depends_on_products?.length ?? 0 > 0,
    [product?.productID]
  );

  const dependantProductData = useMemo(() => {
    if (!isDependantProduct) return null;

    return servicesData.find(
      (prod) => prod?.service?.productID === -product?.productID
    );
  }, [isDependantProduct, servicesData, product?.productID]);

  const amountIncluded = isDependantProduct
    ? dependantProductData?.amount ?? product.amount_included
    : product.amount_included;

  const remainingAmountToSelect = Math.max(
    0,
    (product?.max_amount ?? 0) - (dependantProductData?.amount ?? 0)
  );

  const sticks = useMemo(
    () =>
      Array.from({ length: remainingAmountToSelect }, (_, index) => ({
        value: index + 1,
        label: `${index + 1}`,
        disabled: !isDependantProduct && index < (amountIncluded ?? 0) - 1
      })),
    [remainingAmountToSelect]
  );

  const handleAmount = (newAmount: number, productId: number) => {
    const selectedProduct = servicesData?.find(
      (obj: ServiceType) => obj.service.productID === productId
    );

    if (initialAmountIncluded && dependantProducts?.length > 0) {
      if (selectedProduct?.amount) {
        const amountIncludedDifference = newAmount - selectedProduct.amount;
        let diff = amountIncludedDifference;

        const isAmountIncreased = amountIncludedDifference > 0;

        if (isAmountIncreased) {
          const isPreviousAmountLessThanInitialAmount =
            selectedProduct.amount < initialAmountIncluded;
          const isNewAmountGreaterThanInitialAmount =
            newAmount > initialAmountIncluded;

          if (
            isPreviousAmountLessThanInitialAmount &&
            isNewAmountGreaterThanInitialAmount
          ) {
            diff = diff - (initialAmountIncluded - selectedProduct.amount);
          } else if (
            isPreviousAmountLessThanInitialAmount &&
            !isNewAmountGreaterThanInitialAmount
          ) {
            diff = 0;
          }
        } else {
          const isNewAmountLessThanInitialAmount =
            newAmount < initialAmountIncluded;
          const isPreviousAmountGreaterThanInitialAmount =
            selectedProduct.amount > initialAmountIncluded;

          if (
            isNewAmountLessThanInitialAmount &&
            isPreviousAmountGreaterThanInitialAmount
          ) {
            diff = diff + initialAmountIncluded - newAmount;
          }
        }

        dependantProducts.forEach((dependantProduct) => {
          const dependantProductNegative = servicesData?.find(
            (prod) => prod?.service?.productID === -dependantProduct.productID
          );

          let newAmountIncluded = dependantProductNegative?.amount;

          if (
            dependantProductNegative?.service?.amount_included &&
            product?.amount_included
          ) {
            if (amountIncludedDifference > 0) {
              newAmountIncluded = Math.min(
                dependantProductNegative?.service?.max_amount,
                dependantProductNegative?.amount + diff
              );
            } else {
              const diff1 =
                dependantProductNegative?.service?.amount_included -
                product?.amount_included;

              if (
                dependantProductNegative?.service?.max_amount - diff1 >
                newAmount
              ) {
                newAmountIncluded = Math.max(
                  newAmount + diff1,
                  dependantProductNegative?.service?.amount_included
                );
              }
            }
          }

          const updatedServices = servicesData
            ?.map((obj: ServiceType) => {
              if (
                obj.service.productID ===
                  dependantProductNegative?.service?.productID &&
                obj.service.amount_included
              ) {
                return {
                  service: {
                    ...obj.service
                  },
                  amount: newAmountIncluded
                };
              } else if (
                obj.service.productID ===
                  Math.abs(dependantProductNegative!.service.productID!) &&
                obj.service.amount_included &&
                newAmountIncluded
              ) {
                const newAmountForPositive =
                  obj.service?.max_amount - newAmountIncluded;
                if (newAmountForPositive === 0) return undefined;

                if (newAmountForPositive < obj?.amount) {
                  return {
                    service: {
                      ...obj.service
                    },
                    amount: newAmountForPositive
                  };
                }
              } else if (obj?.service?.productID === productId) {
                return { ...obj, amount: newAmount };
              }

              return obj;
            })
            .filter(Boolean);

          dispatch(setSelectedServices(updatedServices));
        });
      }
    } else {
      const updatedServices = servicesData?.map((obj: ServiceType) => {
        if (obj?.service?.productID === productId) {
          return { ...obj, amount: newAmount };
        }
        return obj;
      });

      dispatch(setSelectedServices(updatedServices));
    }
  };

  const handleAdditionalProductSelect: CheckboxProps['onChange'] = (e) => {
    const { value } = e.target;

    if (isProductSelected) {
      const filteredProducts = servicesData.filter(
        (product: ServiceType) =>
          product?.service?.productID !== value.productID
      );

      dispatch(setSelectedServices(filteredProducts));
      dispatch(removeShippingPrice());
    } else {
      dispatch(
        setSelectedServices([
          ...servicesData,
          {
            service: value,
            amount: !isDependantProduct ? value?.amount_included || 1 : 1
          }
        ])
      );

      const isShippingProduct =
        value?.mandatory_products[0] === SHIPPING_PRODUCT_ID;
      if (isShippingProduct) {
        dispatch(addShippingPrice({ service: shippingProduct, amount: 1 }));
      }
    }
  };

  const isProductSelected = useMemo(() => {
    return (
      isIncluded ||
      servicesData.some(
        (selectedServices: ServiceType) =>
          selectedServices?.service?.productID === product?.productID
      )
    );
  }, [servicesData, product]);

  const setIncludedProducts = () => {
    const productExists = servicesData.some(
      (obj: ServiceType) => obj.service?.productID === product?.productID
    );

    if (
      !productExists &&
      (isIncluded || (isDependantProduct && initialAmountIncluded))
    ) {
      const newService = {
        service: {
          ...product
        },
        amount: product.amount_included || 1
      };

      if (isDependantProduct && initialAmountIncluded) {
        newService.service.productID = -newService.service.productID;
      }

      const isAlreadyIncluded = servicesData.some(
        (service) => service.service?.productID === newService.service.productID
      );

      if (!isAlreadyIncluded) {
        dispatch(setSelectedServices([...servicesData, newService]));
      }
    }
  };

  useEffect(() => {
    setIncludedProducts();
  }, [product, servicesData]);

  const price = calculatePrice(
    product,
    servicesData.find(
      (obj: ServiceType) => obj.service?.productID === product?.productID
    )?.amount || 1,
    !isDependantProduct
  );

  const productNameElement = <p className="package_name">{product.name}</p>;

  return (
    <div
      className={
        product?.marker_type === 1
          ? 'yellow-highlight'
          : isProductSelected
            ? 'package_card_wrapper_selected'
            : 'package_card_wrapper'
      }
    >
      <Row>
        {radio && (
          <Col
            xs={{ span: product.image ? 18 : 24, order: 1 }}
            lg={product?.max_amount > 1 ? 8 : 20}
          >
            {!isIncluded ? (
              <Radio value={product.productID}>{productNameElement}</Radio>
            ) : (
              productNameElement
            )}
          </Col>
        )}

        {checkbox && (
          <Col
            xs={{ span: product.image ? 18 : 24, order: 1 }}
            lg={product?.max_amount > 1 ? (product.image ? 8 : 12) : 18}
          >
            {!isIncluded ? (
              <Checkbox
                className="custom_style_checkbox"
                onChange={handleAdditionalProductSelect}
                value={product}
                checked={
                  sticks?.length > 0 &&
                  servicesData.some(
                    (services) =>
                      services?.service?.productID === product?.productID
                  )
                }
                disabled={sticks?.length === 0}
              >
                {productNameElement}
              </Checkbox>
            ) : (
              productNameElement
            )}
            {(product?.amount_included ?? 0) > 0 && (
              <>
                <p>Bereits enthaltene Anzahl: {amountIncluded}</p>
              </>
            )}
          </Col>
        )}

        {product?.max_amount > 1 && (
          <Col
            xs={{ span: 24, order: 3 }}
            lg={{ span: 12, order: 2 }}
            className={mobileDevice ? 'margin_top_md' : ''}
          >
            <FloatingSelect
              value={
                !isDependantProduct
                  ? selectedPositiveProductData?.amount ||
                    product?.amount_included ||
                    1
                  : selectedPositiveProductData?.amount || 1
              }
              className={style.additional_stick_select}
              onChangeHandler={(value) => {
                handleAmount(parseInt(value), product?.productID);
              }}
              options={sticks}
              disabled={
                sticks?.length === 0 ||
                !servicesData.some(
                  (services) =>
                    services?.service?.productID === product?.productID
                )
              }
              label="Anzahl: "
              defaultValue={1}
            />
          </Col>
        )}

        {product.image && (
          <Col
            xs={{ span: 6, order: 2, offset: 0 }}
            md={{ span: 4, offset: product?.max_amount > 1 ? 1 : 0 }}
            lg={{ span: product?.catID === 2 ? 4 : 3, order: 3 }}
          >
            <img
              src={product.image}
              alt="package_type_logo"
              className="product_image"
            />
          </Col>
        )}
      </Row>

      <Row className={'margin_top_md'}>
        <Col
          span={24}
          dangerouslySetInnerHTML={{
            __html: product.description_long
          }}
          className={style.description_text}
        />
      </Row>

      <Divider />

      <Row justify={'space-between'}>
        <Col xs={24} md={8} className={style.description_link_wrapper}>
          {product.description_link && (
            <a
              className="show_more"
              href={product.description_link}
              target="_blank"
              rel="noreferrer"
            >
              {isInternetProduct ? 'Produktinformationsblatt' : 'Mehr erfahren'}
            </a>
          )}
        </Col>
        <Col xs={24} md={14}>
          <p className="package_price">
            {price.toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}{' '}
            € {product.monthly_billing && '/ Monat'}
          </p>

          {product?.offer_duration !== 0 && (
            <p className="shipping_price_label">
              {`Ab dem ${product?.offer_duration + 1}`}. Monat:{' '}
              <b>
                {product?.price_standard.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}{' '}
                € / Monat
              </b>
            </p>
          )}
        </Col>
      </Row>

      {product?.mandatory_products[0] === SHIPPING_PRODUCT_ID && (
        <Row justify="end">
          <Col span={12}>
            <p className="shipping_price_label">
              + Versandkosten{' '}
              {formatPrice(
                shippingProduct?.price_offer ?? shippingProduct?.price_standard
              )}{' '}
              €
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Product;
